<template>
  <section class="single-storey-houses">
    <section class="hero">
      <div class="hero-container">
        <h1>Immobilier : pourquoi les maisons de plain-pied ont la cote</h1>
      </div>
    </section>
    <div class="hero-img">
      <img
        src="@/assets/img/pages/single-storey-houses-1.jpg"
        alt="Immobilier : pourquoi les maisons de plain-pied ont la cote"
      />
    </div>
    <article class="grid-container article">
      <div class="article-title fit-content">
        <h3>
          Plusieurs enquêtes confirment le gout des français pour les maisons sans étage, ou avec un
          niveau maximum. Un type de bien qu’ils jugent plus pratique sur le plan fonctionnel et
          doté d’un meilleur potentiel de rentabilité, notamment dans la perspective d’une revente.
        </h3>
      </div>
      <div class="article-card">
        <card-type-img>
          <template v-slot:content>
            <p>
              Entre le plan d’une habitation à plusieurs étages et le <router-link to="/maisons/plain-pied">plan d’une maison de
              plain-pied</router-link>, la majorité des français opterait sans hésiter pour le second. Ce
              quasi-plébiscite transparaît dans plusieurs études et sondages récents réalisés à
              partir d’un panel des candidats à l’acquisition interrogés sur les critères qui, à
              leurs yeux, constituent un logement idéal.
            </p>
            <p>
              Parmi les « marqueurs » décisifs à même d’orienter leurs choix, figure celui de la
              configuration générale du lieu et, plus particulièrement, la pertinence de <router-link to="/maisons/nos-modeles">faire
              construire une maison</router-link> en hauteur pour économiser de la superficie de terrain et
              limiter le coût du foncier. Or, d’après les données recueillies par l’agent immobilier
              Century 21 auprès de sa clientèle, la tendance majoritaire actuelle serait, pourtant,
              de concentrer la totalité des pièces sur un seul niveau ou, à défaut, de les répartir
              entre un rez-de-chaussée et un seul étage tout au plus. Un souhait qui se confirme
              dans le résultat d’une consultation réalisée en 2017 par une plateforme d’échange
              entre particuliers, ForumConstruire.com : 59,3% des personnes interrogées marquaient
              leur préférence pour « une maison de plain-pied », un modèle jugé « moins fatigant,
              plus pratique et parfait pour les vieux jours ».
            </p>
          </template>
          <template v-slot:img>
            <img src="@/assets/img/pages/single-storey-houses-2.jpg" alt="Maison de plain-pied" />
          </template>
        </card-type-img>
      </div>
      <div class="article-faster-work fit-content">
        <h2>Travaux plus rapides, économies d’énergie</h2>
        <div class="article-faster-work-text">
          <p>
            L’argumentaire qui plaide en faveur des villas basses repose sur beaucoup d’autres
            motivations : à l’achat ou à la revente du bien, l’absence d’escalier est présentée, non
            comme un « moins » mais, au contraire, comme un « plus » apte à rassurer les jeunes
            couples avec enfants qui redoutent les accidents domestiques. Il répond aussi aux
            attentes des seniors désireux d’évoluer dans un espace accessible où les efforts
            physiques sont limités au maximum.
          </p>
          <p>
            L’entretien général d’un « plain-pied » est également plus simple et rapide que celui
            d’un duplex et, à plus forte raison, d’un triplex dont la hauteur des façades
            extérieures rend difficile l’accès au toit et aux fenêtres. Mener des travaux de
            rénovation dans un habitat bâti sur un seul niveau se révèle souvent moins dangereux et
            aussi moins coûteux.
          </p>
          <p>
            De même, il est plus facile de réaliser des économies d’énergie dans un habitat sans
            étage où la chaleur est, par définition, mieux répartie que dans un logement construit
            sur plusieurs niveaux.
          </p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
import CardTypeImg from '@/components/general/CardTypeImg.vue';

export default {
  name: 'single-storey-houses',
  components: {
    CardTypeImg,
  },
};
</script>

<style lang="sass">
.single-storey-houses
  background-color: $medium-bg
  padding-bottom: 6rem
  .hero
    background-color: darken($primary, 5%)
    height: 35rem
    display: flex
    flex-direction: column
    align-items: center
    &-container
      margin-top: 8rem
      width: 60rem
      h1
        color: $white
        text-align: center
    &-img
      display: flex
      flex-direction: row
      justify-content: center
      img
        border-radius: $global-border-radius
        margin-top: -15rem
  .article
    &-title
      display: flex
      justify-content: center
      margin: 4.5rem auto 0 auto
      h3
        text-align: center
    &-card
      margin: 4.5rem auto 0 auto
      p:not(:last-child)
        margin-bottom: 1rem
      a
        font-weight: normal !important
    &-faster-work
      margin: 4.5rem auto 0 auto
      h2
        text-align: center
        color: $primary
      &-text
        margin-top: 3rem
        p:not(:last-child)
          margin-bottom: 1rem
        a
          @include hyperlink
          text-decoration: none
          font-size: inherit
          font-weight: normal
  .fit-content
    width: 90%
    max-width: 950px
  @media (max-width: 768px)
    .hero
      height: 25rem
      &-container
        margin-top: 6rem
        width: 90%
      &-img
        img
          object-fit: cover
          width: 90%
          height: 260px
          margin-top: -9rem
    .article
      &-title
        margin: 2.5rem auto 0 auto
      &-card
        margin: 2.5rem auto 0 auto
      &-faster-work
        margin: 3rem auto 0 auto
        &-text
          margin-top: 1.5rem
</style>
